import './App.css';
import banner from "./img/ea-guzman.jpg";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <img src={banner} className="banner" alt="2ª Jornada de Escuela de Alcaldes" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="border-light mb-3 centrado" role="alert">
            <h5>3ª JORNADA DE LA ESCUELA DE ALCALDES 2024</h5>
            <h4><i>Comunidades energéticas, la energía en mano de las personas</i></h4>
            <hr></hr>
            <h6><strong>Fecha:</strong> 2 de julio de 2024</h6>
            <h6><strong>Lugar:</strong> Palacio de Guzmán, Calle San Juan 17 - Guzmán (Burgos)</h6>
            <h6><strong>Horario:</strong> de 09:30 a 18:00</h6>
            <h6><strong>Plazo:</strong> 28 de junio a las 15:00</h6>
            </div>
          </div>
        </div>
        <div className="card text-white w-100 caracteristicas colorfondo">
          <div className="card-body">
            <h5 className="card-title"><i class="fas fa-hand-point-right"></i>&nbsp;&nbsp;INSCRIPCIÓN GRATUITA&nbsp;&nbsp;<i class="fas fa-hand-point-left"></i></h5>
            <a href="#form"><p><strong>RESERVA TU PLAZA</strong></p></a>
            <p>Inscripciones hasta el 28 de junio a las 15:00 h.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card border-secondary w-100 caracteristicas izquierda">
            <div className="card-body">
                <h5 className="card-title centrado">Agenda:</h5>
                <li><strong className='colortext'>9:30 h.- </strong> Recepción y acreditación de participantes.</li>
                <li><strong className='colortext'>9:45 h.- </strong> Saluda de autoridades y explicación de la evolución de la jornada</li>
                <li><strong className='colortext'>10:00 h.- </strong>Comunidad energética de Guzmán. Premio Fuentes Claras 2023.
                  <ul>
                    <p><i>D. Antonio Páramo Casado, Socio Fundador y Presidente de la Asociación Guzmán renovable.</i></p>
                    <p><i>Raúl Páramo Flores, Socio Fundador y miembro de la Asociación Guzmán renovable.</i></p>
                  </ul>
                </li>
                <li><strong className='colortext'>10:30 h.- </strong> Recuperación de la ribera del río Henar a su paso por Cilleruelo “Repuente limpio y vivo”. Premio Fuentes Claras 2023.
                  <ul>
                    <p><i>Dª. Raquel casado Muñoz, Presidenta de la Asociación Cultural Collaba, Cilleruelo de Abajo (Burgos).</i></p>
                  </ul>
                </li>
                <li><strong className='colortext'>11:00 h.- </strong> Planes de Acción para el Clima y Energía Sostenible. Iniciativa Europea del Pacto de las Alcaldías.
                  <ul>
                    <p><i>Puy Domínguez Pérez. Técnico en el Ente Regional de la Energía de Castilla y León</i></p>
                  </ul>
                </li>
                <li><strong className='colortext'>11:30 h.- </strong> Café.
                </li>
                <li><strong className='colortext'>12:00 h.- </strong> Dinámica “Comunidades Energéticas Paso a Paso”.
                  <ul>
                    <p><i>Santiago Campos. Equipo Técnico de Energética Coop.</i></p>
                  </ul>
                  <ul>
                    <p><strong className='colortext'>- 12:00 a 13:00 h.-</strong> Presentación de Conceptos Clave sobre Comunidades Energéticas</p>
                    <p><strong className='colortext'>- 13:00 a 14:45 h.-</strong> Taller participativo: Itinerario para una Comunidad Energética en tu municipio.</p>
                  </ul>
                </li>
                <li><strong className='colortext'>14:45 h.- </strong> Evaluación y clausura de la jornada.
                </li>
                <li><strong className='colortext'>15:00 h.- </strong> Comida (20 €/persona).
                </li>
                <li><strong className='colortext'>17:00 h.- </strong> Visita del proyecto premiado.
                  <ul>
                    <p><i>En un paseo por las calles del pueblo, los protagonistas, nos mostrarán y explicarán las actuaciones realizadas en estos últimos años integradas en el proyecto y otros recursos del municipio</i></p> 
                    <p>La siguiente actividad se realiza con el proyecto <em>¿Te Enseño mi Pueblo?</em> del programa de voluntariado Ribera Voluntariis, que también fue galardonado en 2019 con el Premios Fuentes Claras en la categoría de Asociaciones y Entidades sin ánimo de lucro.teenseñomipueblo.es</p>
                  </ul>
                </li>
              </div>
            </div>
          </div>
        </div>
        <div className="row inscripcion" id="formulario">
          <div className="col-12">
            <div className="alert alert-light text-center" role="alert" id="form">
              <h3>Formulario de inscripción</h3>
              <hr />
              <iframe src="//cursos.itagraformacion.com/form/1804" width="95%" height="1150px" title='formulario inscripción'><p>Tu navegador no admite iframes.</p></iframe>
            </div>
          </div>
        </div>

        <div className='container-fluid'>
          <div className="row">
            <div className="col-12 info">
              Más información <i class="fas fa-phone-square-alt"></i>
              &nbsp;979 10 83 03&nbsp;
              <i className="fas fa-paper-plane"></i>&nbsp;inscripciones@itagraformacion.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
